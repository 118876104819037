body {
    margin: 0;
    font-family: Inter;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    /* -webkit-overflow-scrolling: touch; */
}

html {
    height: -webkit-fill-available;
}
code {
    /* font-family: 'Inter'; */
}


.css-20k79e-MuiButtonGroup-root .MuiButtonGroup-firstButton, .css-20k79e-MuiButtonGroup-root .MuiButtonGroup-middleButton{
    border: solid 1px black;
  }
  .css-20k79e-MuiButtonGroup-root .MuiButtonGroup-lastButton, .css-20k79e-MuiButtonGroup-root .MuiButtonGroup-middleButton{
    border: solid 1px black;
  }