.button-group {
    display: inline-flex;
  }
  
  .main-button {
    text-transform: none;
    padding-left: 16px;
    padding-right: 16px;
    color: black !important;
    text-transform: none !important;
  }
  
  .arrow-button {
    padding: 0 8px;
    color: black !important;
  }