@font-face {
    font-family: 'Inter';
    src: local('Inter'),
        url(./assets/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 900;
    src: local('Inter'), url(./assets/fonts/Inter-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 900;
    src: local('Inter'), url(./assets/fonts/Inter-Black.ttf) format('truetype');
}
.circler-container {
    // overflow: hidden;
    .active {
        border-radius: 0px 100px 100px 0px;
        background: #0061db;
        width: 4px;
        height: 48px;
        padding: 0;
    }
    .active:hover {
        text-decoration: none;
    }

    .custom-date-range-wrapper {
        /* Your custom styles here */
        border: 1px solid #e5e6eb;
        border-radius: 12px;
        overflow: auto;
    }

    .gif_player {
        margin: 1rem;

        img {
            width: 500px;
        }
    }
}
